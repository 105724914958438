import Enum from './_Enum';

let Universe = {

  // const PURSCHASE_ORDER = 'PURSCHASE_ORDER';
  PURSCHASE_ORDER: {
    id: 'PURSCHASE_ORDER',
    name: 'Bon de commande',
    color: '#2A0944'
  },

  // const DELIVERY_CHECKLIST = 'DELIVERY_CHECKLIST';
  DELIVERY_CHECKLIST: {
    id: 'DELIVERY_CHECKLIST',
    name: 'Checklist de livraison',
    color: '#4E9F3D'
  },

  // const DELIVERY_NOTE = 'DELIVERY_NOTE';
  DELIVERY_NOTE: {
    id: 'DELIVERY_NOTE',
    name: 'Bon de livraison',
    color: '#916BBF'
  },

  // const WARRANTY_STATEMENT = 'WARRANTY_STATEMENT';
  WARRANTY_STATEMENT: {
    id: 'WARRANTY_STATEMENT',
    name: 'Déclaration de garantie',
    color: '#247291'
  },

  // const DEVICE_LOCK = 'DEVICE_LOCK';
  DEVICE_LOCK: {
    id: 'DEVICE_LOCK',
    name: 'Code de vérouillage',
    color: '#ED6663'
  },

  // const DEVICE_MAIL = 'DEVICE_MAIL';
  DEVICE_MAIL: {
    id: 'DEVICE_MAIL',
    name: 'Boîte mail',
    color: '#36C2CE'
  },

  // const AFTER_SALES = 'AFTER_SALES';
  AFTER_SALES: {
    id: 'AFTER_SALES',
    name: 'SAV',
    color: '#DC0083'
  },
};

export default Enum(Universe, undefined, (id, values) => {
  return values.find((value) => value.id === id);
});
