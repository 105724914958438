import React from 'react';
import PropTypes from 'prop-types'

import { withRouter } from "react-router-dom";

import {
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons';

import { Avatar, Layout, Menu, Popover, Space } from 'antd'
const { Sider, Header, Content } = Layout;

import { Link } from 'react-router-dom';

import { Breadcrumb, Flex, VersionInfos } from 'app/components';

import { Typeof } from 'app/utils';
import { Auth } from 'app/services';

import moment from 'moment';

import './style.scoped.css';

import logo from 'app/assets/logo.png'

class GenericPage extends React.Component {

  constructor(props) {
    super(props);

    //Store routes
    let routes = {};

    routes['customers'] = '/customers';
    routes['simulators'] = '/simulators';
    routes['equipments'] = '/equipments';

    routes['boards'] = '/boards';

    routes['firmwares'] = '/firmwares';

    this.routes = routes;
  }

  handleNavigation = (item) => {

    let route = this.routes[item.key];

    if(Typeof.string(route)) {

      const { history } = this.props;
      history.push(route);
    }
  }

  render() {

    const { history } = this.props;

    let user = Auth.user;

    let location = history.location.pathname;
    let selectedPath = null;

    for (const [key, path] of Object.entries(this.routes))
    {
      if(location.startsWith(path))
      {
        selectedPath = [key];
        break;
      }
    }

    const { children } = this.props;

    let routes = [
      {
        type: 'group',
        label: 'Gestion',
        children: [
          {
            key: 'customers',
            label: 'Clients'
          },
          {
            key: 'simulators',
            label: 'Simulateurs'
          }
        ]
      },

      { type: 'divider' },

      // Hardware
      {
        type: 'group',
        label: 'Materiel',
        children: [
          {
            key: 'equipments',
            label: 'Équipements'
          },
          {
            key: 'boards',
            label: 'Cartes'
          }
        ]
      },

      { type: 'divider' },

      // Firmware
      {
        key: 'firmwares',
        label: 'Micrologiciels'
      }
    ];

    return (
      <Layout>

        {/* Menu */}
        <Sider
          width={250}
          breakpoint="md"
          // trigger={null} collapsible
        >
          <Flex vertical className="sider">

            <a href="/">
              <img src={logo} className="logo"/>
            </a>

            <Menu className="menu"
              mode="inline"
              onClick={this.handleNavigation}
              items={routes}
              defaultSelectedKeys={selectedPath}
              />

            <div className='version'>
              <VersionInfos />
            </div>
          </Flex>
        </Sider>

        <Layout>

          {/* Navigation */}
          <Header>


            <div className="header">

              {/* Breadcrumb */}
              <Breadcrumb />

              <Popover placement="bottomRight" content={
                <Flex vertical>
                  <span id="last-auth">{moment(Auth.user.last_auth).format('[Connecté le] DD/MM/YYYY [à] HH:mm:ss')}</span>
                  <Link to="/logout" id="logout">
                     <LogoutOutlined />
                      Déconnexion
                  </Link>
                </Flex>
              }>

                <span>
                  <Flex className="account" space={8}>

                    {/* Infos */}
                    <Flex vertical alignRight space={4}>
                      <span id="username">{user.username}</span>
                      <span id="email">{user.email}</span>
                    </Flex>

                    <Avatar src={user.avatar} />

                  </Flex>
                </span>

              </Popover>

            </div>

          </Header>

          <Content className="content">
            <div>
              {children}
            </div>

          </Content>

        </Layout>
      </Layout>
    )
  }
}

GenericPage.defaultProps = {
  dummy: false,
  navigation: true
};

GenericPage.propTypes = {
  dummy: PropTypes.bool.isRequired,
  navigation: PropTypes.bool.isRequired
};

export default withRouter(GenericPage);
