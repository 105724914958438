import React from 'react'
import PropTypes from 'prop-types'

import { Collapse, Divider, DatePicker, Button, Form, Input, Select, Badge } from 'antd';
import { Col, Row } from 'antd';

const { Panel } = Collapse;
const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

import { Flex } from 'app/components';

import { Typeof } from 'app/utils';

import moment from 'moment';

class TimelineFilters extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);

    const { source } = props;

    let fields = [
      { name: ['timerange'] },
      { name: ['search'], value: source?.search },
      { name: ['tags'],   value: source?.tags },
      { name: ['events'], value: source?.events }
    ]

    this.state = {
      collapsed: true,
      fields: fields
    }
  }

  handleCollapse = () => {

    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  handleSubmit = async (data) => {

    const { onFilter } = this.props;
    const { timerange, search, tags, events } = data;

    let criteria = {};

    // Timerange
    if(timerange) {

      // Start
      const start = timerange[0];

      if(start) {

        //Convert to UTC
        criteria.start = moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ssZ');
      }

      // End
      const end = timerange[1];

      if (end) {
        //Convert to UTC
        criteria.end = moment.utc(end).format('YYYY-MM-DD[T]HH:mm:ssZ');
      }
    }

    // Search
    if(search) {
      criteria.search = search?.trim()

      // Empty
      if(criteria.search.length === 0) {
        delete criteria.search;
      }
    }

    // Tags
    if(tags && tags.length > 0) {
      criteria.tags = tags;
    }

    // Events
    if (events && events.length > 0) {
      criteria.events = events;
    }

    this.setState({
      isDirty: false
    }, () => {

      onFilter(criteria);
    });
  }

  handleReset = () => {

    const { onFilter } = this.props;
    const { fields } = this.state;

    this.setState({
      isDirty: false,
      collapsed: true,
      fields: fields.map((field) => {

        return {
          ...field,
          value: undefined
        };
      })
    }, () => {

      onFilter({});
    });
  }

  handleFieldsChange = (_, fields) => {

    this.setState({
      fields: fields,
      isDirty: true
    });
  }

  handleModeChange = (mode) => {
    this.setState({
      mode: mode
    })
  }

  render() {

    const { tags, events } = this.props;
    const { isDirty, collapsed, fields } = this.state;

    let eventsWithoutCategory = [];
    let eventsByCategory = {};

    events.values().forEach(event => {

      if(event.category) {

        let events = eventsByCategory[event.category] ?? [];
        events.push(event);
        eventsByCategory[event.category] = events;

      } else {
        eventsWithoutCategory.push(event);
      }

    });

    let renderEvent = (event) => {
      return (
        <Option value={event.type} label={event.name} key={event.type}>
          {event.name}
        </Option>
      )
    };

    return (
      <Collapse collapsible="header" activeKey={collapsed ? false : 0} onChange={this.handleCollapse} destroyInactivePanel={true}>
        <Panel key={0} header="Recherche avancée" extra={!collapsed &&
          <Button form="timeline-form" type="link" onClick={this.handleReset} size="small">
            Réinitialiser
          </Button>
        }>

          <div>

            <Form
              name="timeline-form"
              fields={fields}
              layout="vertical"
              onFieldsChange={this.handleFieldsChange}
              onFinish={this.handleSubmit}
              ref={this.formRef}
            >

              {/* Timerange */}
              <Form.Item name="timerange" label="Plage temporelle">
                <RangePicker showTime showNow allowEmpty={[true, true]} format="DD/MM/YYYY HH:mm:ss" placeholder={['Depuis', "Jusqu'au"]} style={{ width: '100%' }} />
              </Form.Item>

              <Row gutter={16}>

                <Col span={12}>

                  <Divider>Journal de bord</Divider>

                  {/* Search */}
                  <Form.Item name="search" label="Recherche">
                    <Input placeholder="Rechercher dans le titre, contenu, etc..." />
                  </Form.Item>

                  {/* Tags */}
                  <Form.Item name="tags" label="Étiquette(s)">
                    <Select mode="multiple" placeholder="Aucune étiquette">
                      {tags.values().map((tag) => {
                        return (
                          <Option value={tag.id} label={tag.name} key={tag.id} color={tag.color}>
                            <Badge count={tag.name} style={{ backgroundColor: tag.color }} />
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>

                </Col>

                <Col span={12}>

                  <Divider>Activité</Divider>

                  {/* Events */}
                  <Form.Item name="events" label="Évenement(s)">
                    <Select mode="multiple" placeholder="Aucune évenement" >

                      {/* Events without category */}
                      {eventsWithoutCategory.map(renderEvent)}

                      {/* Events with category */}
                      {Object.entries(eventsByCategory).map(([category, events]) => {

                        return (
                          <OptGroup label={category}>
                            {events.map(renderEvent)}
                          </OptGroup>
                        )
                      })}

                    </Select>
                  </Form.Item>

                </Col>

              </Row>

              <Flex alignRight>

                <Button form="timeline-form" type="primary" htmlType="submit" disabled={!isDirty}>
                  Appliquer
                </Button>

              </Flex>

            </Form>

          </div>
        </Panel>
      </Collapse>
    );
  }
}

export default TimelineFilters;
