import React from 'react'
import PropTypes from 'prop-types'

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

import {
  PlusOutlined,
  FileImageOutlined,
  FileOutlined,
  DeleteOutlined,
  FilePdfOutlined
} from '@ant-design/icons';

import moment from 'moment';
import prettyBytes from 'pretty-bytes';

import { Timeline as AntTimeline, Tag, Button, Card, Badge, Divider, Popconfirm, Tooltip, Drawer, Empty, Avatar } from 'antd';

import { message } from 'antd';

import { Flex, Discrete, Unknown, TimelineFilters } from 'app/components';
import { LogbookForm } from 'app/forms';

import { API, Auth } from 'app/services';

import { Notifications } from 'app/utils';

import classnames from 'classnames';

import './style.scoped.css';

const LIMIT = 10;

class Timeline extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      timeline: [],
      more: false,
      limit: LIMIT
    };
  }

  componentDidMount = async () => {
    await this.fetch();
  }

  fetch = async () => {

    const { origin } = this.props;

    try {

      const { limit, criteria } = this.state;

      let response = await API.get(`${origin}/timeline`, { limit: limit, ...criteria })

      this.setState({
        isFetching: false,
        ...response.data
      });

    } catch (error) {

    }
  }

  handleFilter = (criteria) => {

    this.setState({
      isFetching: true,
      criteria: criteria
    }, this.fetch);
  }

  handleLoadMore = () => {

    const { limit } = this.state;

    this.setState({
      isFetching: true,
      limit: limit + LIMIT
    }, this.fetch);
  }

  handleDownload = async (attachment) => {
    await API.open(`/attachments/${attachment.uuid}`)
  }

  handleDelete = async (entry) => {

    const { origin } = this.props;

    await API.delete(`${origin}/logbook/${entry.id}`)
      .then(() => {

        this.fetch();

        message.success(`L'entrée à bien été supprimée`);
      })
      .catch((error) => {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de la suppression de l'entrée",
          error.message
        )
      });
  }

  toggleForm = () => {

    this.setState({
      isFormOpen: !this.state.isFormOpen
    });

  }

  handleFormSuccess = async (entry) => {

    await this.fetch();
    this.toggleForm();
  }

  renderEvent = (item) => {

    const { source } = this.props;

    const eventsEnum = this.props.events;
    const event = eventsEnum.parse(item.type);

    const { repetition, initiator } = item
    const max = 10

    return (
      <AntTimeline.Item dot={event?.icon} className='event'>
        <Flex vertical alignLeft>

          {/* Message */}
          <Badge count={repetition} showZero={false} offset={[repetition > max ? 25 : 15, 0]} overflowCount={max} color='blue'>
            {event ? (
              <>
                {event.message(item.data, source)}
              </>
            ) : (

              // Unknown event
              <Unknown color="red">
                {item.type}
              </Unknown>
            )}

          </Badge>

          <Discrete>

            {/* Timestamp */}
            {moment(item.timestamp).format('[Le] DD/MM/YYYY [à] HH:mm:ss')}

            {/* Initiator */}
            {initiator &&
              <>
                <Divider type='vertical' className='avatar'/>
                  <Tooltip title={Auth.user?.email == initiator.email ? "C'est moi !" : initiator.email} placement='bottom'>
                    <Avatar style={{ width: '1em', height: '1em' }} src={initiator.avatar} />
                    {` ${initiator.username}`}
                </Tooltip>
              </>
            }
          </Discrete>
        </Flex>
      </AntTimeline.Item>
    )
  }

  renderLogbookEntry = (item) => {

    const tagEnum = this.props.tags;
    const { title, content, tags, attachments, initiator } = item;

    const hasContent = content || attachments && attachments.length > 0;

    const classes = classnames('entry', {
      'empty': !hasContent
    });

    return (
      <AntTimeline.Item>
        <Card hoverable className={classes} title={
          <>

            {/* Title */}
            <span className="title">
              {title}
            </span>

            <Discrete>

              {/* Timestamp */}
              {moment(item.timestamp).format('[Le] DD/MM/YYYY [à] HH:mm:ss')}

              {/* Initiator */}
              {initiator &&
                <>
                  <Divider type='vertical' className='avatar' />
                  <Tooltip title={Auth.user?.email == initiator.email ? "C'est moi !" : initiator.email} placement='bottom'>
                    <Avatar style={{ width: '1em', height: '1em' }} src={initiator.avatar} />
                    {` ${initiator.username}`}
                  </Tooltip>
                </>
              }
            </Discrete>

          </>
        } extra={
          <Flex centerV space={8}>

            {/* Tags */}
            {tags &&
              <>
                {tags.map((rawTag, i) => {

                  const tag = tagEnum.parse(rawTag);

                  return (
                    <Badge key={i} count={tag?.name ?? rawTag} style={{ backgroundColor: tag.color ?? "red" }} />
                  )
                })}
              </>
            }

            {/* Delete */}
            <Popconfirm
              title={`Êtes-vous sûr de vouloir supprimer cette entrée ?`}
              onConfirm={() => this.handleDelete(item)}
              okText="Oui"
              cancelText="Non"
            >
              <Tooltip title="Supprimer">
                <Button type="danger" shape="circle">
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </Flex>
        } style={{ maxWidth: '500px' }}>

          {/* Content */}
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {content}
          </ReactMarkdown>

          {/* Attachments */}
          {attachments && attachments.length > 0 &&

            <>
              <Divider className="attachments-divider" plain>Pièce(s) jointe(s)</Divider>

              <Flex vertical space={4}>
                {attachments.map((attachment) => this.renderAttachment(attachment))}
              </Flex>

            </>
          }

        </Card>
      </AntTimeline.Item>
    );
  }

  renderAttachment = (attachment) => {

    const { uuid, name, type, size } = attachment;

    let icon;

    switch (type) {
      case "image/png":
      case "image/jpg":
        icon = <FileImageOutlined />
        break;

      case "application/pdf":
        icon = <FilePdfOutlined />
        break;

      default:
        icon = <FileOutlined />
        break;
    }

    return (
      <a onClick={() => this.handleDownload(attachment)} key={uuid}>

        <Flex centerV space={8}>

          {/* File icon */}
          {icon}

          {/* File name */}
          <span>
            {name}
          </span>

          {/* File size */}
          <Tag>
            {prettyBytes(size, { locale: 'fr' })}
          </Tag>

        </Flex>

      </a>
    )
  }

  render() {

    const { origin, tags, events } = this.props;
    const { isFetching, more, criteria, timeline, isFormOpen } = this.state;

    return (
      <div>

        {/* Filters */}
        <TimelineFilters tags={tags} events={events} source={criteria} onFilter={this.handleFilter} />

        {/* Timeline */}
        <div style={{ maxWidth: '500px', margin: 'auto' }}>

          <AntTimeline id="timeline" pending={isFetching ? "Chargement..." : false}>

            {/* New entry */}
            <AntTimeline.Item dot={
              <Button type="primary" shape="circle" onClick={this.toggleForm}>
                <PlusOutlined />
              </Button>
            }>

              <span style={{ opacity: 0.5 }}>Ajouter une nouvelle entrée</span>

            </AntTimeline.Item>

            {/* Timeline */}
            {timeline && timeline.length > 0 ? (
              <>
                {timeline.map((event, i) => {

                  const { type } = event;

                  if (type) {

                    // Event
                    return (
                      <div key={i}>
                        {this.renderEvent(event)}
                      </div>
                    )
                  } else {

                    // Logbook entry
                    return (
                      <div key={i}>
                        {this.renderLogbookEntry(event)}
                      </div>
                    )
                  }

                })}
              </>
            ) : (
              <>
                {!isFetching &&
                  <AntTimeline.Item>
                    <Empty description="Oops il n'y a rien ici" />
                  </AntTimeline.Item>
                }
              </>
            )}

            {/* Load more */}
            {more &&
              <AntTimeline.Item>
                <Button type="primary" onClick={this.handleLoadMore} disabled={isFetching}>
                  Charger plus...
                </Button>
              </AntTimeline.Item>
            }

          </AntTimeline>

          {/* Modal */}
          <Drawer title="Ajouter une entrée" placement="right" visible={isFormOpen} onClose={this.toggleForm} maskClosable={false} >

            {isFormOpen &&
              <LogbookForm tags={tags.values()} onSuccess={this.handleFormSuccess} makeRequest={(_, data) => API.post(`${origin}/logbook`, data)} />
            }

          </Drawer>

        </div>

      </div>
    )
  }
}

export default Timeline;
